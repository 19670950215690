import React, { useState, useRef } from "react";
import "./ProductItemProducts.css";
import { useNavigate } from "react-router-dom";
import productsWithoutScore from "../../pages/v2/jsons/products_without_score.json";

const ProductItem = ({ productObject, handleMainpage }) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  const handleNavigate = (productId) => {
    navigate(`/products?productId=${productId}`);
  };

  // Função para obter a letra da classificação com base na pontuação
  const getLetterGrade = (score) => {
    if (score < 0 || score > 100) {
      return "Null";
    } else if (score >= 85.0) {
      return "A";
    } else if (score >= 75.0) {
      return "B";
    } else if (score >= 65.0) {
      return "C";
    } else if (score >= 55.0) {
      return "D";
    } else if (score >= 45.0) {
      return "E";
    } else if (score < 45.0) {
      return "F";
    } else {
      return "Null";
    }
  };

  // Ativação da letra com base na pontuação do produto
  const activeLetter = getLetterGrade(productObject.score);

  // Verificar se o produto tem pontuação com base no tipo
  const shouldDisplayScore = productObject.dpp.type !== 'DPP_TYPE_NO_SCORE';

  const containerRef = useRef(null);

  return (
    <div className="productitem-container-item">
      <div
        onClick={() => handleNavigate(productObject.id)}
        className="productitem-img-score-item"
      >
        <img
          src={productObject.img}
          alt="ProductImage"
          className="productitem-img-item"
        />

        <div className="productitem-container">
          <div className="bottom-info-container-product-item">
            <div className="productitem-texts-item-white">
              <div className="productitem-name-item-white">
                {/* {productObject.name} */}
              </div>
              <div className="productitem-brand-item-white">
                {/* {productObject.brand} */}
              </div>
            </div>
            {shouldDisplayScore && (
              <div className="productitem-score-container-border-item">
                <div
                  className={`productitem-score-container-item ${activeLetter}`}
                >
                  {activeLetter}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="productitem-texts-item">
        <div className="productitem-name-item">{productObject.name}</div>
        <div className="productitem-brand-item">{productObject.brand}</div>
      </div>
    </div>
  );
};

export default ProductItem;
