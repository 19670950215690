import React, { useState, useRef, useEffect } from "react";
import "./Filters.css";
import arrowClose from "../../img/filters-close.svg";
import product_type from "../../pages/v2/jsons/type.json";

const Filters = ({
  selectedBrands,
  setSelectedBrands,
  selectedTypes,
  setSelectedTypes,
  selectedGrades,
  setSelectedGrades,
  brands,
  types,
  onFiltersChange,
  onApplyFilters,
  initialScoreRange,
}) => {
  const filterChildComponentRef = useRef();
  const brandScrollContainerRef = useRef();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showBrands, setShowBrands] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showTypes, setShowTypes] = useState(false);

    // Filter brands based on search term
    const filteredBrands = brands.filter((brand) =>
      brand.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const getProductTypeDescription = (code) => {
    for (const type of product_type) {
      const product_type_item = type.options.find(
        (product_type_item) => product_type_item.code === code
      );
      if (product_type_item) {
        return product_type_item.description.en;
      }
    }
    return "-";
  };

  const letterGrades = [
    { letter: "A", range: [85, 100] },
    { letter: "B", range: [75, 84] },
    { letter: "C", range: [65, 74] },
    { letter: "D", range: [55, 64] },
    { letter: "E", range: [45, 54] },
    { letter: "F", range: [0, 44] },
  ];

  // Handler to select/deselect a brand
  const handleSelectBrand = (brand) => {
    const isSelected = selectedBrands.includes(brand);

    if (isSelected) {
      // If brand is already selected, remove it
      const updatedBrands = selectedBrands.filter((b) => b !== brand);
      setSelectedBrands(updatedBrands);
    } else {
      // If brand is not selected, add it
      const updatedBrands = [...selectedBrands, brand];
      setSelectedBrands(updatedBrands);
    }
  };

  // Handler to select/deselect a type
  const handleSelectType = (type) => {
    const isSelected = selectedTypes.includes(type);

    if (isSelected) {
      // If type is already selected, remove it
      const updatedTypes = selectedTypes.filter((b) => b !== type);
      setSelectedTypes(updatedTypes);
    } else {
      // If type is not selected, add it
      const updatedTypes = [...selectedTypes, type];
      setSelectedTypes(updatedTypes);
    }
  };

  // Handler to select/deselect letters and manage up to 2 selections
  const handleSelectGrade = (grade) => {
    const isSelected = selectedGrades.find((g) => g.letter === grade.letter);

    if (isSelected) {
      const updatedGrades = selectedGrades.filter(
        (g) => g.letter !== grade.letter
      );
      setSelectedGrades(updatedGrades);
    } else if (selectedGrades.length < 2) {
      const updatedGrades = [...selectedGrades, grade];
      setSelectedGrades(updatedGrades);
    } else {
      const updatedGrades = [selectedGrades[1], grade];
      setSelectedGrades(updatedGrades);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    // Horizontal scroll with mouse wheel for brand-scroll-container
    useEffect(() => {
      const handleWheelScroll = (e) => {
        if (brandScrollContainerRef.current) {
          // Only scroll horizontally if there's vertical wheel movement
          if (e.deltaY !== 0) {
            e.preventDefault();
            brandScrollContainerRef.current.scrollLeft += e.deltaY;
          }
        }
      };
  
      const brandContainer = brandScrollContainerRef.current;
      if (brandContainer) {
        brandContainer.addEventListener("wheel", handleWheelScroll);
      }
  
      return () => {
        if (brandContainer) {
          brandContainer.removeEventListener("wheel", handleWheelScroll);
        }
      };
    }, []);


  useEffect(() => {
    if (
      filterChildComponentRef.current &&
      typeof filterChildComponentRef.current.setRange === "function"
    ) {
      filterChildComponentRef.current.setRange(initialScoreRange);
    }
  }, [initialScoreRange]);

  // Calculate the numeric range based on selected grades
  const getScoreRange = () => {
    if (selectedGrades.length === 0) return null;

    const sortedGrades = selectedGrades.sort((a, b) => a.range[0] - b.range[0]);
    const minScore = sortedGrades[0].range[0];
    const maxScore = sortedGrades[sortedGrades.length - 1].range[1];

    return [minScore, maxScore];
  };

  // Apply filters and pass to parent
  const handleApplyFilters = () => {
    const scoreRange = getScoreRange();
    onFiltersChange(scoreRange, selectedBrands, selectedTypes);
    onApplyFilters();
  };

  const handleClearFilters = () => {
    setSelectedGrades([]);
    setSelectedBrands([]);
    setSelectedTypes([]);
    onFiltersChange();
  };

  return (
    <div
      className={isMobile ? "panel-filters open" : "panel-filters-desktop open"}
    >
      <div className="container-filters-max-width">
        <div
          className={
            isMobile ? "container-filters" : "container-filters-desktop"
          }
        >
          <div className="score">
            <div className="score-content">
              <div className="score-text-filters">Score</div>
              <div className="scale-v2">
                {letterGrades.map((grade, index) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={`letter ${
                      selectedGrades.some((g) => g.letter === grade.letter)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleSelectGrade(grade)}
                  >
                    {grade.letter}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="brand">
            <div className="brand-content">
              <div className="brand-header">
                <div className="score-text-filters">Brand</div>
                <img
                  alt="arrow-filters"
                  className="arrow-filters"
                  src={arrowClose}
                  style={{
                    transform: showBrands ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={() => setShowBrands((prev) => !prev)}
                />
              </div>
              <div className="brand-search-container">
                <input
                  className="brand-search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img
                  className="brand-search-img"
                  alt="brand-search-img"
                  src="./images/search_lupa.svg"
                />
              </div>
              <div
                className={`brand-scroll-container ${showBrands ? "full" : ""}`}
                ref={brandScrollContainerRef}
              >
                {filteredBrands.map((brand, index) => (
                  <div
                    key={index}
                    className={`brand-item ${
                      selectedBrands.includes(brand) ? "selected" : ""
                    }`}
                    onClick={() => handleSelectBrand(brand)}
                  >
                    {brand}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="type">
            <div className="type-content">
              <div className="type-header">
                <div className="score-text-filters">Type</div>
                <img
                  alt="arrow-filters"
                  className="arrow-filters"
                  src={arrowClose}
                  style={{
                    transform: showTypes ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={() => setShowTypes((prev) => !prev)}
                />
              </div>
              <div
                className={`type-scroll-container ${showTypes ? "full" : ""}`}
                ref={brandScrollContainerRef}
              >
                {types.map((type, index) => (
                  <div
                    key={index}
                    className={`type-item ${
                      selectedTypes.includes(type) ? "selected" : ""
                    }`}
                    onClick={() => handleSelectType(type)}
                  >
                    {getProductTypeDescription(type)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-filters">
          <div className="clear-filters" onClick={handleClearFilters}>
            Clear
          </div>
          <div className="apply-filters" onClick={handleApplyFilters}>
            Apply
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
