import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./Homepage.css";

// Components
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import SlideContentV2 from "../../components/v2/homepage/SlideContentV2";
import ButtonRedirect from "../../components/desktop/ButtonRedirect";
import searchicondesktop from '../../img/search_desktop.svg';
import LanguageSelection from "../../components/desktop/LanguageSelection";
import NavbarDesktop from "../../components/desktop/NavbarDesktop";


const Homepage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const homepageSlideContent = [
    {
      id: 1,
      img: "../images/dpp-1.png",
    },
    {
      id: 2,
      img: "../images/dpp-2.png",
    },
    {
      id: 3,
      img: "../images/dpp-3.png",
    },
  ];
  
  const languages = ["EN", "PT", "DE", "ES", "FR"];

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  return (
    <div className="home-container">
      {/* {screenWidth > 768 && (
        <div className="navbar-desktop-homepage">
          <div className="navbar-right-desktop-homepage">
            {screenWidth > 768 && (
              <Link to="/search" className="navbar-item-desktop-homepage">
                <img
                  className="icon-search-desktop-homepage"
                  src={searchicondesktop}
                  alt="Ícone"
                ></img>
              </Link>
            )}
            <LanguageSelection isHomepage={true} languages={languages} />
          </div>
        </div>
      )} */}
      {/* {screenWidth > 768 && ( */}
      <video
        className="background-video"
        preload="auto"
        autoPlay
        loop
        muted
        playsInline
        poster="./images/video_poster.jpg"
      >
        <source src="./images/video_homepage_v3.mp4" type="video/mp4" />
        Your browser does not support the video.
      </video>

      {/* )} */}
      {/* {screenWidth > 768 && (
       <div className="aboutus-container">
        <div className="aboutus-text">About Us</div>
        <img
          src="./images/icon_swipe_arrows.svg"
          alt="aboutus-arrow"
          className="aboutus-arrow"
        />
      </div> 
       )} */}
      {/* {screenWidth > 768 && ( */}
      <div className="homepage-mid-content">
        <img
          src="./images/logo_dpp_homepage.svg"
          className="logo-dpp-homepage"
          alt="logo-dpp-homepage"
        />
        <div className="homepage-mid-content-text">
          The Digital Product Passport (DPP) appears as a way to make
          transparent the value chain of the fashion industry. The aim is to
          provide more information to the final consumer about how products are
          made, in order to influence your purchasing decision.
        </div>
        <ButtonRedirect />
      </div>
      {/* )} */}
      {/* {screenWidth <= 768 && (
        <div className="slider-container">
          <SlideContentV2 homepageSlideContent={homepageSlideContent} />
        </div>
      )} */}
      {screenWidth <= 768 ? (
        <div className="navbar-footer">
          <Navbar isHomepage={true} MenuClick={handleOpenMenu} />
          <OverlayComponent isOpen={isMenuOpen} onClose={handleCloseMenu} />
        </div>
      ) : (
        <div className="page-products-navbar">
          <NavbarDesktop isHomepage={true} />
        </div>
      )}
    </div>
  );
};

export default Homepage;
