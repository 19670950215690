import "./Navbar.css"
import { Link, useLocation } from 'react-router-dom';


import homeicon from '../../../img/home.svg';
import productsicon from '../../../img/products.svg';
import searchicon from '../../../img/search.svg';
import menu from '../../../img/menu.svg';
import dpp from  '../../../img/dpp_logo.svg';
import homepageIconSelected from '../../../img/home-selected.svg';
import productsIconSelected from '../../../img/products-selected.svg';
import searchiconSelected from '../../../img/search-selected.svg';



const Navbar = ({ MenuClick, isHomepage }) => {
  // Obtém a rota atual
  const location = useLocation();

  // Verifica a rota atual
  const isProductsRoute = location.pathname === "/view";
  const isSearchRoute = location.pathname === "/search";
  const isHomepageRoute = location.pathname === "/";

  return (
    <div className={`navbar ${isHomepage ? "ishomepage" : ""}`}>
      <div className={`navbar-left ${isHomepage ? "ishomepage" : ""}`}>
        <Link
          translate="no"
          to="/"
          className={`navbar-item ${isHomepage ? "ishomepage" : ""}`}
        >
          <img
            src={isHomepageRoute ? homepageIconSelected : homeicon}
            alt="Home"
            style={{
              filter: "invert(1)",
            }}
          ></img>
          Home
        </Link>
        <Link
          to="/view"
          className={`navbar-item ${isHomepage ? "ishomepage" : ""}`}
        >
          <img
            src={isProductsRoute ? productsIconSelected : productsicon}
            alt="Ícone"
          />
          Products
        </Link>
      </div>
      <div className={`navbar-center ${isHomepage ? "ishomepage" : ""}`}>
        <Link
          to="/"
          className={`navbar-item-center ${isHomepage ? "ishomepage" : ""}`}
        >
          <img src={dpp} alt="Ícone" />
        </Link>
      </div>
      <div className={`navbar-right ${isHomepage ? "ishomepage" : ""}`}>
        <Link
          to="/search"
          className={`navbar-item ${isHomepage ? "ishomepage" : ""}`}
        >
          <img
            src={isSearchRoute ? searchiconSelected : searchicon}
            alt="Ícone"
          ></img>
          Search
        </Link>
        <a
          href="#"
          className={`navbar-item ${isHomepage ? "ishomepage" : ""}`}
          onClick={MenuClick}
        >
          <img src={menu} alt="Ícone"></img>
          Menu
        </a>
      </div>
    </div>
  );
};

export default Navbar
